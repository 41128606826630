<template>
  <div>
    <c-table
      title="LBL0000210"
      tableId="swp01"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="400px"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit-state-detail',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          year: '',
          plantCd: '',
          issuedDeptCd: '',
          processCd: '',
          swpStepCd: '',
          permitTypeCd: '',
          supplementWorkTypeCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'workSummary',
            field: 'workSummary',
            // 작업개요
            label: 'LBL0000211',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:250px',
          },
          {
            name: 'swpStepName',
            field: 'swpStepName',
            // 진행단계
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            // 결재진행단계
            label: 'LBL0000212',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            // 허가일
            label: 'LBL0000213',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'permitTypeName',
            field: 'permitTypeName',
            // 허가서구분
            label: 'LBL0000204',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'supplementWorkTypeNames',
            field: 'supplementWorkTypeNames',
            // 보충작업
            label: 'LBL0000205',
            align: 'left',
            sortable: true,
            style: 'width:200px',
          },
          {
            name: 'applicationDeptName',
            field: 'applicationDeptName',
            // 신청부서
            label: 'LBL0000202',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
          {
            name: 'issuedDeptName',
            field: 'issuedDeptName',
            // 발급부서
            label: 'LBL0000203',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.popupParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
  }
};
</script>
